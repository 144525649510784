@font-face {
  font-family: "San Francisco";
  font-weight: normal;
  src: url("https://www.apple.com/wss/fonts/SF-Pro-Display/v3/sf-pro-display_regular.woff2");
}

@font-face {
  font-family: "San Francisco";
  font-weight: /*semi*/ bold;
  src: url("https://www.apple.com/wss/fonts/SF-Pro-Display/v3/sf-pro-display_semibold.woff2");
}

:root {
  /* SETUP */
  /* colors */
  --center-blue: #174489;
  --black: #000000;
  --white: #ffffff;
  --tum-blue: #0065BD;
  --tum-light-blue: #64A0C8;
  --tum-mid-blue: #005293;
  --tum-dark-blue: #003359;
  --tum-mid-grey: #808080;
  --tum-dark-grey: #333333;
  --tum-orange: #E37222;

  /* other stuff */
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "San Francisco", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  /* light mode */
  --accent-color: var(--tum-dark-blue);
  --background-color: var(--white);
  --hover-color: var(--tum-mid-blue);
}

@media screen and (prefers-color-scheme: dark) {
  :root {
    /* dark mode */
    --accent-color: var(--tum-mid-blue);
    --background-color: var(--black);
    --hover-color: var(--tum-blue);
  }
}

body {
  margin: 0;
  background-color: var(--background-color);
}

.nav {
  background-color: var(--accent-color);
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  text-align: center;
  z-index: 1;
  width: 100%;
  color: var(--background-color);
}

.nav a {
  z-index: 1;
  padding: 1vw 2vw;
  text-decoration: none;
  font-size: 3vh;
  color: var(--background-color);
  display: block;
  margin: 0 auto;
  width: max-content;
}

/* .nav a:hover {
  background-color: var(--hover-color);
  color: var(--background-color);
} */

.site {
  text-align: center;
  margin: 0 auto;
  height: auto;
  padding: 15vh 5% 0 5%;
}

.img-circle {
  --border-radius: 50%;
  max-width: 45vh;
  max-height: 45vh;
  text-align: center;
  border: .8vh solid var(--accent-color);
  /*box-shadow: 0 0 0 0.4em var(--accent-color);*/
  border-radius: var(--border-radius);
  display: inline;
}

/* .img-circle:hover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: var(--border-radius);
  border: 0.8vh solid var(--hover-color);
  /optional: bigger border when hovering/ box-shadow: 0 0 0 0.6em var(--accent-color);
} */

.social-icons, .social-icons a, .social-icons i {
  color: var(--accent-color);
  text-align: center;
  line-height: 1%;
  font-size: 10vh;
  margin-top: 8vh;
  margin-bottom: 0;
}

.social-icons a:hover, .social-icons i:hover {
  color: var(--hover-color);
}

@media (max-width: 480px) {
  .social-icons {
    font-size: 16vw;
  }
  .nav a {
    font-size: 6vw;
  }
}
